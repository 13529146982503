import React from "react";
import '../assets/styles/Home.css';
import AppAboutMe from "../components/AppAboutMe";

const About = () => {

        return (
            <div>
                <AppAboutMe/>
            </div>
        );
};

export default About;
